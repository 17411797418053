import axios from "axios";

export const bucketUrl =
  process.env.REACT_APP_API_URL || "http://localhost:3000/api/v2";
export const explorerUrl = process.env.REACT_APP_S3_EXPLORERUrl;
export const hostnameUrl = process.env.REACT_APP_AUTH_REDIRECT_URI;


const $bucketUrlHost = axios.create({
  baseURL: bucketUrl,
});

const $explorerUrlHost = axios.create({
  baseURL: explorerUrl,
});



export { $bucketUrlHost, $explorerUrlHost };
