import React, { memo } from "react";
import { Typography } from "antd";

import "./style.scss";

const { Title } = Typography;

const Main = () => {
    return (
        <div className="main p60">
            <div className="inner-main">
                <Title level={1} className="main-title">
                    Nexus: Your One-Stop Shop for PH R&D Data & AI
                </Title>
                <div className="main-subtitle">
                    Unlock the Power of Data Science and AI with seamless self-service capabilities
                </div>
                <p className="main-descr">
                    Nexus is your gateway to a unified Data, Data Science, and AI/ML platform. This self-service portal simplifies access to tools, data, and resources, accelerating your journey from insights to action. Explore, analyze, and build with cutting-edge capabilities at your fingertips.
                </p>
            </div>            
        </div>
    );
};

export default memo(Main);
