import { Tag, Modal, message as antMessage } from "antd";
import { isAxiosError, AxiosError } from "axios";
import { isDevelopment } from "../const";
import { v4 as uuidv4 } from "uuid";

import { ERoles, IPond, IPondParams } from "../pages/Ponds/types";
import {
  Group,
  GroupsToDisplay,
} from "components/Users/components/AccessGroups/types";
import { Role, Roles } from "components/Users/components/Roles/types";

const MAIN_ERROR_MSG = "Something went wrong";

export const getHeaders = (token: string | void) => ({
  "x-amzn-oidc-accesstoken": process.env.REACT_APP_API_ACCESS_TOKEN,
  Authorization:
    !isDevelopment && token
      ? `Bearer ${token}`
      : `Bearer ${process.env.REACT_APP_API_ACCESS_TOKEN}`,
  "Content-Type": "application/json",
});

export const getAdditionalDetails = (params: IPondParams) => {
  const details = [];
  if (params.athena.enable) {
    details.push({
      name: "Athena",
      color: "green",
    });
  }
  if (params.deep_archive) {
    details.push({
      name: "Archive",
      color: "geekblue",
    });
  }
  if (params.colid) {
    details.push({
      name: "COLID",
      color: "volcano",
    });
  }

  if (!details.length) {
    return "-";
  } else {
    return details.map(item => (
      <Tag color={item.color} key={item.name}>
        {item.name}
      </Tag>
    ));
  }
};

export const convertBytes = (bytes: number) => {
  let result = "";

  const kb = bytes / 1024,
    mb = kb / 1024,
    gb = mb / 1024;

  if (kb <= 1024) {
    result = kb.toFixed(2) + " KB";
    result.split(".")[0];
  } else if (kb >= 1024 && mb <= 1024) {
    result = mb.toFixed(2) + " MB";
  } else if (mb >= 1024 && gb <= 1024) {
    result = gb.toFixed(2) + " GB";
  }

  return result;
};

export const generateUser = (users: {
  [index: string]: { level: keyof typeof ERoles; prefix: "" }[];
}) => {
  const usersOptions = [];
  let userKey = 0;

  for (const [name, value] of Object.entries(users)) {
    usersOptions.push({
      key: userKey,
      email: {
        name,
        itemKey: userKey,
      },
      select: {
        value: value.length === 1 && value[0].prefix === "" ? "all" : "specify",
        itemKey: userKey,
      },
      type: {
        value: value.map(item => ({
          level: item.level,
          prefix: item.prefix,
          prefixKey: uuidv4(),
        })),
        itemKey: userKey,
      },
    });

    userKey++;
  }

  return usersOptions;
};

export const generateGroup = (groups?: Group[]): GroupsToDisplay[] => {
  if (!groups || !Array.isArray(groups)) return [];

  const groupsOptions = [];
  let groupKey = 0;

  for (const { id, name, permissions } of groups) {
    groupsOptions.push({
      key: groupKey,
      name: {
        id,
        name,
        itemKey: groupKey,
      },
      select: {
        value:
          permissions?.length === 1 && permissions[0].prefix === ""
            ? "all"
            : "specify",
        itemKey: groupKey,
      },
      type: {
        value: permissions?.map(permission => ({
          level: permission.level,
          prefix: permission.prefix,
          prefixKey: uuidv4(),
        })),
        itemKey: groupKey,
      },
    });

    groupKey++;
  }

  return groupsOptions;
};

export const generateApplication = (applications: {
  [index: string]: { level: keyof typeof ERoles; prefix: "" }[];
}) => {
  const applicationsOptions = [];
  let userKey = 0;

  for (const [name, value] of Object.entries(applications)) {
    applicationsOptions.push({
      key: userKey,
      applicationId: {
        name,
        itemKey: userKey,
      },
      select: {
        value: value.length === 1 && value[0].prefix === "" ? "all" : "specify",
        itemKey: userKey,
      },
      type: {
        value: value.map(item => ({
          level: item.level,
          prefix: item.prefix,
          prefixKey: uuidv4(),
        })),
        itemKey: userKey,
      },
    });

    userKey++;
  }

  return applicationsOptions;
};

const roleDivider = "{{next-role}}";
export const makeRolesKey = (roles: string[]) => roles.join(roleDivider);
export const getRolesFromKey = (roles: string) => roles.split(roleDivider);

export const convertRoleToRoles = (roles: Role[]) =>
  roles.reduce(
    (acc, { role, service_role, permissions }) => (
      (acc[makeRolesKey([role, service_role])] = permissions), acc
    ),
    {} as Roles,
  );

export const generateRoles = (roles: Roles) => {
  const roleOptions = [];
  let roleKey = 0;

  for (const [name, value] of Object.entries(roles)) {
    roleOptions.push({
      key: roleKey,
      name: {
        name,
        itemKey: roleKey,
      },
      select: {
        value: value.length === 1 && value[0].prefix === "" ? "all" : "specify",
        itemKey: roleKey,
      },
      type: {
        value: value.map(item => ({
          level: item.level,
          prefix: item.prefix,
          created_by: item.created_by ? item.created_by : "",
          prefixKey: uuidv4(),
        })),
        itemKey: roleKey,
      },
    });

    roleKey++;
  }

  return roleOptions;
};

export const handleRequestError = (error: AxiosError | { message: string }) => {
  if (isAxiosError(error)) {
    if (error.response?.status === 422) {
      return {
        error: (
          error.response.data as {
            detail: Array<{
              msg: string;
            }>;
          }
        ).detail
          .map(el => el.msg)
          .join("\n"),
        data: null,
      };
    } else {
      return {
        error:
          (error.response?.data as { detail: string })?.detail ||
          MAIN_ERROR_MSG,
        data: null,
      };
    }
  } else {
    return {
      data: null,
      error: (error as { message: string })?.message || MAIN_ERROR_MSG,
    };
  }
};

export const generatedRoles = (
  roles: {
    info: {
      level: string;
      prefix: string;
      created_by?: string;
    }[];
    name: string;
  }[],
  prefixes: {
    key: number;
    level: keyof typeof ERoles;
    prefix: string;
    created_by?: string;
  }[],
  name: string,
  selectFolder: string,
  allFolder: keyof typeof ERoles,
) => {
  const finishRoles: {
    [index: string]: {
      level: keyof typeof ERoles;
      prefix: string;
      created_by: string;
    }[];
  } = {};

  if (selectFolder === "all") {
    Object.assign(finishRoles, {
      [name]: [
        {
          level: allFolder,
          prefix: "",
        },
      ],
    });

    return finishRoles;
  } else {
    const finishPrefixes = prefixes.map(({ key, ...rest }) => rest);

    roles.forEach(item => {
      Object.assign(finishRoles, {
        [item.name]: item.info,
      });
    });

    Object.assign(finishRoles, {
      [name]: finishPrefixes,
    });

    return finishRoles;
  }
};

export const multilineMessageContent = (
  message: string,
  title: null | string = null,
) => {
  return (
    <>
      {title ? <b>{title}</b> : ""}
      <ul>
        {message.split("\n").map(el => (
          <li>{el}</li>
        ))}
      </ul>
    </>
  );
};

export const errorMessageContent = (
  message: string | null,
  title: string | null = null,
  default_: string | null = null,
) => {
  return message
    ? message && message.indexOf("\n") != 1
      ? multilineMessageContent(message, title || "Something went wrong.")
      : message
    : default_ || "Something went wrong. Try reloading";
};

export const modalError = (
  message: string | null,
  title: string | null = null,
) => {
  Modal.error({
    title: title || "Error",
    className: "modal-error-role",
    content: multilineMessageContent(
      message || "Something went wrong. Try reloading",
    ),
  });
};

export const handleCopyText =
  (name: string, text?: string, duration = 1.5) =>
  () => {
    if (text) {
      navigator.clipboard.writeText(text);
      antMessage.success({
        content: `${name} ${name.slice(-1) === "s" ? "are" : "is"} copied`,
        duration,
      });
    } else {
      antMessage.error({
        content: "Something wrong",
        duration,
      });
    }
  };

export const getTextFromCodeBlock = (
  element: HTMLElement | null,
  classForIgnore = "copy-icon",
  classForLineBreak = "snippet-mb",
  classForDoubleParagraph = "snippet-padding-plus",
  classForParagraph = "snippet-padding",
) => {
  if (element?.children) {
    return Array.from(element.children)
      .filter(({ className }) => !className.includes(classForIgnore))
      .map(({ className, innerHTML }) => {
        if (className.includes(classForLineBreak)) {
          return `${innerHTML}\n`;
        }
        if (className.includes(classForDoubleParagraph)) {
          return `\t\t${innerHTML}`;
        }
        if (className.includes(classForParagraph)) {
          return `\t${innerHTML}`;
        }
        return innerHTML;
      })
      .join("\n");
  } else {
    return "";
  }
};

export const arrayToMapByField = <T extends Record<string, unknown>>(
  field: string,
  list: T[],
) => list?.reduce((acc, value) => acc.set(value[field], value), new Map());

export const findRole = (currentItem: IPond) => {
  if (currentItem.access_levels) {
    const perm = Object.values(currentItem.access_levels)[0];
    return ERoles[perm];
  }

  return ERoles.ro;
};

export const formatDateTime = (number: number) => {
  const timestamp = new Date(number * 1000);
  const dtFormat = new Intl.DateTimeFormat("en-GB", {
    dateStyle: "medium",
    timeStyle: "medium",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC",
  });

  return dtFormat.format(new Date(timestamp));
};

export const subtractDaysFromDate = (date: Date, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - days);

  return newDate;
};

export const dateToISO = (date: Date, time?: boolean) => {
  if (time) {
    return date.toISOString();
  } else {
    return date.toISOString().slice(0, 10);
  }
};

export const noop = () => {};
