import { Routes, Route } from "react-router-dom";
import Pond from "pages/Pond";
import AddPond from "pages/AddPond";
import Ponds from "pages/Ponds";
import NotFound from "pages/NotFound";
import Main from "pages/Main";
import AnalyticsZone from "pages/AnalyticsZone";

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Ponds />}></Route>
            <Route path="/home" element={<Main />}></Route>
            <Route path="/:id" element={<Pond />}></Route>
            <Route path="/addPond" element={<AddPond />}></Route>
            <Route path="/analyticsZone" element={<AnalyticsZone />}></Route>
            <Route path="/404" element={<NotFound />}></Route>
        </Routes>
    );
}

export default AppRoutes;
