import React, { ChangeEvent, Dispatch, useCallback, useState } from "react";
import { Input, Typography, Select, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Button from "common/Button";
import "./style.scss";
import { useRegions } from "hooks/requests/useRegions";

const { Search } = Input;
const { Title } = Typography;

interface IProps {
  setSearchItems: Dispatch<string>;
  setSelectedRegion: Dispatch<string>;
}

const ListPageHeader: React.FC<IProps> = ({ setSearchItems, setSelectedRegion }) => {

  const handleSearchItems = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchItems(e.target.value);
    },
    [setSearchItems],
  );

  const { regions, isLoading } = useRegions();

  const handleRegionChange = (value: string) => {
      setSelectedRegion(value);
  };

  return (
    <div className="list-header">
      <Title level={2} className="lh-title">
        Data Domains List
      </Title>
      <div className="lh-wrapper">
        <Search
          placeholder="Search Data Domains"
          className="lh-search"
          onChange={handleSearchItems}
        />
        
        <Row>
          <Col span={9} style={{ textAlign: "right" }}>
            <Title level={5} style={{ marginRight: 10, marginTop: 8 }}>Region:</Title>
          </Col>
          <Col span={15}>
            <Select size={"large"}
                defaultValue={"eu-central-1"} 
                onChange={handleRegionChange}
              >
                {regions.map(region => (
                      <Select.Option key={1} value={region}>
                        {region}
                      </Select.Option>
                    ))}
            </Select>
          </Col>
        </Row>

        <Link to="/addPond" className="vh">
          <Button size="large" icon={<PlusOutlined />} type="primary">
            Add pond
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default React.memo(ListPageHeader);
